// COMETA
// Bootstrap overrides
//
// Copy variables from `_variables.scss` to this file to override default values
// without modifying source files.

/* custom font */
@import url("https://fonts.googleapis.com/css?family=Montserrat");

$body-bg: #f8f9fb;

$font-family-base: calibri, roboto, montserrat, -apple-system, system-ui, blinkmacsystemfont, "Segoe UI", "Helvetica Neue", arial, sans-serif;

$nav-tabs-active-link-hover-bg: 			transparent;
$nav-tabs-active-link-hover-color:          #5cb9b1;
$nav-tabs-active-link-hover-border-color:	#5cb9b1;
$nav-tabs-link-hover-border-color:			transparent;

// table stripped
$table-bg-accent: #f1eee9;

// padding des cellules des tableaux
$table-cell-padding: 5px;

$progress-bar-color: #04425b;

$nav-tabs-link-active-color: #009fe1;
$nav-tabs-link-active-bg: #fff;

$link-color: #a3a3a3;
$navbar-light-color: #a3a3a3;
